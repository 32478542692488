import { acceptHMRUpdate, defineStore } from 'pinia';
import { type UserProfile, type LoginCredentials } from '@/types';
import { useUserApi } from '~/composables/useUserApi';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type { AxiosError } from 'axios';

interface State {
  isAuthenticated: boolean
  token: string
  user: UserProfile | null
  isLoading: boolean
  // errors: string[]
}

export const useAuth = defineStore('auth', {
  persist: {
    storage: persistedState.cookiesWithOptions({ sameSite: 'strict', maxAge: 365 * 24 }),
    key: 'auth',
    paths: ['isAuthenticated', 'token', 'user'],
  },
  state: (): State => ({
    isLoading: false,
    isAuthenticated: false,
    token: '',
    user: null as UserProfile | null,
  }),
  actions: {
    async authenticate (user: LoginCredentials) {
      const userApi = useUserApi();
      this.isLoading = true;
      try {
        const { data: loginResponse } = await userApi.login(user);
        this.isAuthenticated = loginResponse?.status === 'logged';
        this.token = loginResponse?.token;
        await this.getProfile();

        return navigateTo('/');
      } catch (error: AxiosError | any) {
        // eslint-disable-next-line no-console
        console.log('error: ', error?.response);
        // this.errors.push(error?.response);
      } finally {
        this.isLoading = false;
      }
    },
    async getProfile () {
      const userApi = useUserApi();
      const { data: profileResponse } = await userApi.getProfile();
      const profile = profileResponse.profile as UserProfile;
      this.user = profile;
    },
    async logOut () {
      const userApi = useUserApi();
      this.isLoading = true;
      try {
        await userApi.logOut();
        this.isAuthenticated = false;
        this.token = '';
        this.user = null;
      } catch (error: AxiosError | any) {
        // eslint-disable-next-line no-console
        console.log('error: ', error?.response);
        // this.errors.push(error?.response);
      } finally {
        this.isLoading = false;
      }
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAuth, import.meta.hot));
}
