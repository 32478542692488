import Axios, { type AxiosResponse } from 'axios';
import type { LoginCredentials, LoginUser, UserProfile } from '~/types';
import qs from 'qs';

export function useUserApi () {
  const { API_URL: baseURL } = useRuntimeConfig().public;

  const getToken = () => {
    const cookie = useCookie('auth')?.value as unknown as Record<string, any>;
    const token = cookie.token;

    return `Token ${token}`;
  };

  const axios = Axios.create({
    baseURL,
    timeout: 15000,
    headers: {
      accept: 'application/json',
    },
  });

  axios.interceptors.request.use((config) => {
    const url = config.url;
    const requiresToken = ['/profile/get/', '/logout/'].some(needle => needle === url);

    if (requiresToken) {
      const token = getToken();
      if (token) {
        config.headers.Authorization = token;
      }
    }

    return config;
  });

  const login = async (user: LoginCredentials): Promise<AxiosResponse<LoginUser>> => {
    const res = await axios.post('/login/', qs.stringify(user));

    return res;
  };

  const getProfile = async (): Promise<AxiosResponse<{status: string, profile: UserProfile}>> => {
    return await axios.post('/profile/get/');
  };

  const logOut = async (): Promise<AxiosResponse> => {
    return await axios.post('/logout/');
  };

  return {
    login,
    logOut,
    getProfile,
  };
}
